import { ref, watch } from "@vue/composition-api";

// Notification
import liveChatRepository from "@/abstraction/repository/liveChatRepository";

const repository = new liveChatRepository();
export default function usemoodList() {
    const refplaylistListTable = ref(null);
    const livechatLists = ref([]);
    const liveLastMessages = ref([]);
    const perPage = ref(10);
    const perPageOptions = [10, 25, 50, 100];
    const sortBy = ref("id");
    const currentPage = ref(1);
    const searchQuery = ref("");
    const isSortDirDesc = ref(true);
    const roleFilter = ref(null);
    const planFilter = ref(null);
    const statusFilter = ref(null);
    const pagination = ref({});

    const refetchData = () => {
        refplaylistListTable.value.refresh();
    };

    watch([currentPage, perPage, searchQuery, roleFilter, planFilter, statusFilter], () => {
        refetchData();
    });

    // Table Handlers
    const tableColumns = [
        // { key: 'list', sortable: true },
        { key: "title", sortable: true },
        { key: "creator", sortable: true },
        { key: "start_time", sortable: true },
        { key: "activeStatus", sortable: true },
        { key: "status", sortable: true },
        { key: "actions" },
    ];
    const addNewMessage = async (data) => {
        try {
            return await repository.addNewMessage(data);
        } catch (e) {
            return false;
        }
    };
    const uploadVideo = async (data) => {
        try {
            return await repository.uploadVideo(data);
        } catch (e) {
            return false;
        }
    };
    const storeLiveChat = async (data) => {
        try {
            return await repository.store(data);
        } catch (e) {
            return false;
        }
    };

    const showLiveChat = async (id) => {
        try {
            return await repository.show(id);
        } catch (e) {
            return false;
        }
    };
    const showBanUsers = async (id) => {
        try {
            return await repository.banUsers(id);
        } catch (e) {
            return false;
        }
    };
    const banLiveChatUser = async (userId, liveId) => {
        try {
            return await repository.ban(userId, liveId);
        } catch (e) {
            return false;
        }
    };
    const unbanLiveChatUser = async (userId, liveId) => {
        try {
            return await repository.unban(userId, liveId);
        } catch (e) {
            return false;
        }
    };
    const activeChat = async (liveId) => {
        try {
            return await repository.activeChat(liveId);
        } catch (e) {
            return false;
        }
    };
    const addLiveVideo = async (liveId, vimeoId) => {
        try {
            return await repository.addLiveVideo(liveId, vimeoId);
        } catch (e) {
            return false;
        }
    };
    const deActiveChat = async (liveId) => {
        try {
            return await repository.deActiveChat(liveId);
        } catch (e) {
            return false;
        }
    };
    const activeLive = async (liveId) => {
        try {
            return await repository.activeLive(liveId);
        } catch (e) {
            return false;
        }
    };
    const deActiveLive = async (liveId) => {
        try {
            return await repository.deActiveLive(liveId);
        } catch (e) {
            return false;
        }
    };
    const pinMessage = async (msgId) => {
        try {
            return await repository.pinMsg(msgId);
        } catch (e) {
            return false;
        }
    };
    const unPinMessage = async (msgId) => {
        try {
            return await repository.unPinMsg(msgId);
        } catch (e) {
            return false;
        }
    };
    const pinedMessages = async (liveId) => {
        try {
            return await repository.pinedMsg(liveId);
        } catch (e) {
            return false;
        }
    };
    const showActiveLive = async () => {
        try {
            return await repository.showActiveLive();
        } catch (e) {
            return false;
        }
    };
    const finishChat = async (liveId) => {
        try {
            return await repository.finishChat(liveId);
        } catch (e) {
            return false;
        }
    };
    const updateLiveChat = async (id, data) => {
        try {
            return await repository.update(id, data);
        } catch (e) {
            return false;
        }
    };
    const deleteMsg = async (id) => {
        try {
            return await repository.deleteMsg(id);
        } catch (e) {
            return false;
        }
    };
    const storeLiveStreamData = async (data) => {
        try {
            return await repository.storeLiveStreamData(data);
        } catch (e) {
            return false;
        }
    };
    const showLiveStreamData = async (id) => {
        try {
            return await repository.showLiveStreamData(id);
        } catch (e) {
            return false;
        }
    };
    const showLiveStreamRequests = async (id) => {
        try {
            return await repository.showLiveStreamRequests(id);
        } catch (e) {
            return false;
        }
    };
    const inviteUserToStream = async (data) => {
        try {
            return await repository.inviteUserToStream(data);
        } catch (e) {
            return false;
        }
    };
    const cancelInvite = async (data) => {
        try {
            return await repository.cancelInvite(data);
        } catch (e) {
            return false;
        }
    };
    const endStream = async (liveId, userId) => {
        try {
            return await repository.endStream(liveId, userId);
        } catch (e) {
            return false;
        }
    };
    const removeRequests = async (id) => {
        try {
            return await repository.removeRequest(id);
        } catch (e) {
            return false;
        }
    };
    const fetchLivechatList = async (paginate = { page: 1, itemsPerPage: perPage.value }, filters = {}) => {
        const livechatList = await repository.index({ filters, pagination: paginate });
        console.log(livechatList);
        livechatLists.value = livechatList.data;
        pagination.value = livechatList.pagination;
        return livechatLists;
    };
    const liveId = ref();
    const fetchLiveLastMessages = async (paginate = { page: 1, itemsPerPage: perPage.value }, filters = {}) => {
        const response = await repository.liveLastMessagesHandler({ filters, pagination: paginate }, liveId.value);
        liveLastMessages.value = response.data;
        pagination.value = response.pagination;
        return liveLastMessages;
    };

    const deleteLiveChat = async (id) => {
        try {
            const response = await repository.delete(id);
            pagination.value.itemsLength--;
            const index = livechatLists.value.findIndex((x) => x.id === id);
            livechatLists.value.splice(index, 1);
            return response;
        } catch (e) {
            return false;
        }
    };

    const changePagePlaylist = async (page) => {
        const response = await fetchLivechatList({ pagination: page });
        return response;
    };

    // *===============================================---*
    // *--------- UI ---------------------------------------*
    // *===============================================---*

    return {
        tableColumns,
        livechatLists,
        pagination,
        perPageOptions,
        perPage,
        sortBy,
        currentPage,
        storeLiveChat,
        showLiveChat,
        updateLiveChat,
        fetchLivechatList,
        deleteLiveChat,
        addNewMessage,
        banLiveChatUser,
        activeChat,
        deActiveChat,
        activeLive,
        deActiveLive,
        showActiveLive,
        finishChat,
        uploadVideo,
        addLiveVideo,
        fetchLiveLastMessages,
        liveLastMessages,
        liveId,
        deleteMsg,
        pinMessage,
        unPinMessage,
        pinedMessages,
        showBanUsers,
        unbanLiveChatUser,
        storeLiveStreamData,
        showLiveStreamData,
        showLiveStreamRequests,
        inviteUserToStream,
        cancelInvite,
        endStream,
        removeRequests,
    };
}
