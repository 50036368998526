import { ref } from '@vue/composition-api'

// Notification
import FileRepository from '@/abstraction/repository/fileRepository'

const repository = new FileRepository({ errorHandle: false })
export default function useFile2(batchId, collectionName, modelName, modelId = null) {
    const postFileForm =  ref({
        batch_id: batchId,
        collection_name: collectionName,
        model_name: modelName,
        model_id: modelId,
        file: null,
        crop: {},
    })

    const postStoreFile = async () => {
        try {
            return await repository.store(postFileForm.value)
        } catch (e) {
            return false
        }
    }

    // *===============================================---*
    // *--------- UI ---------------------------------------*
    // *===============================================---*

    return {
        postFileForm,
        postStoreFile,
    }
}
