<template>
    <component :is="form === undefined ? 'div' : 'b-card'">
        <template>
            <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
                <!-- Form -->
                <b-form class="p-2" @submit.prevent="handleSubmit(onSubmit)" @reset.prevent="resetForm">
                    <b-row class="justify-content-between">
                        <div class="col-4">
                            <!-- media -->
                            <!-- <b-media class="col-5" no-body> -->
                            <!-- <b-media-body class="mt-75 ml-75"> -->
                            <!-- <b-row> -->
                            <!-- <div class="col-12"> -->
                            <!-- upload button -->
                            <div class="row justify-content-center">
                                <b-form-checkbox checked="false" v-model="form.show_home_banner" :value="true" class="custom-control-info" name="check-button" switch />
                            </div>
                            <b-card-text class="row justify-content-center font-weight-bolder"> Show Home Banner </b-card-text>
                            <label for="homeBannner"> Home Banner </label>
                            <image-cropper name="homeBannner" class="col-12" v-model="fileForm" label="manager image" :url.sync="form.thumbnail" />
                            <!--/ upload button -->
                            <!-- </div> -->
                            <!-- </b-row> -->
                            <b-card-text>Allowed JPG, GIF or PNG. Max size of 800kB</b-card-text>
                            <!-- </b-media-body> -->
                            <!-- </b-media> -->
                            <!--/ media -->
                        </div>
                        <div class="col-4">
                            <div class="row justify-content-center">
                                <b-form-checkbox checked="false" v-model="form.show_tv_icon" :value="true" class="custom-control-info" name="check-button" switch />
                            </div>
                            <b-card-text class="row justify-content-center font-weight-bolder"> Show Tv Icon </b-card-text>
                        </div>
                        <div class="col-4">
                            <!-- <b-media class="col-5" no-body> -->
                            <!-- <b-media-body class="mt-75 ml-75"> -->
                            <!-- <b-row> -->
                            <!-- <div class="col-6"> -->
                            <!-- upload button -->
                            <div class="row justify-content-center">
                                <b-form-checkbox checked="false" v-model="form.show_post_banner" :value="true" class="custom-control-info" name="check-button" switch />
                            </div>
                            <b-card-text class="row justify-content-center font-weight-bolder"> Show Post Banner </b-card-text>
                            <label for="postBannner"> Post Banner </label>
                            <image-cropper name="postBannner" class="col-12" v-model="postFileForm" label="post image" :url.sync="form.thumbnail2" />
                            <!--/ upload button -->
                            <!-- </div>
                                    </b-row> -->
                            <b-card-text>Allowed JPG, GIF or PNG. Max size of 800kB</b-card-text>

                            <!-- </b-media-body>
                            </b-media> -->
                        </div>
                    </b-row>
                    <b-row v-if="liveId" class="justify-content-center mt-1">
                        <video v-if="form.video !== null" width="650" :src="videoSrc" controls></video>
                    </b-row>
                    <b-row class="mt-3 justify-content-center">
                        <!-- Name -->
                        <b-col cols="12" sm="6" md="6">
                            <validation-provider #default="validationContext" name="title" rules="required">
                                <b-form-group label="Title" label-for="title">
                                    <b-form-input id="title" v-model="form.title" :state="getValidationState(validationContext)" trim />

                                    <b-form-invalid-feedback>
                                        {{ validationContext.errors[0] }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                        </b-col>
                        <b-col cols="12" sm="6" md="6">
                            <validation-provider #default="validationContext" name="link">
                                <b-form-group label="Link" label-for="title">
                                    <b-form-input id="title" v-model="form.link" :state="getValidationState(validationContext)" trim />
                                </b-form-group>
                            </validation-provider>
                        </b-col>
                        <b-col cols="12" sm="6" md="4">
                            <validation-provider #default="validationContext" name="start_time" rules="required">
                                <b-form-group label="Start Time" label-for="start_time">
                                    <b-form-datepicker id="datepicker-placeholder" placeholder="Choose a Time" local="en" :initial-date="date" class="mx-1" v-model="start_time" />

                                    <b-form-invalid-feedback>
                                        {{ validationContext.errors[0] }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                        </b-col>
                        <b-col cols="12" sm="6" md="2">
                            <b-form-timepicker class="mt-2" v-model="time" show-seconds locale="en" />
                        </b-col>
                        <b-col cols="12">
                            <validation-provider name="Text">
                                <b-form-group label="Text" label-for="Text">
                                    <b-form-textarea v-model="form.text" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :reduce="(val) => val.value" input-id="Text" rows="4" />
                                </b-form-group>
                            </validation-provider>
                        </b-col>
                        <!-- Form Actions -->
                        <b-col cols="12">
                            <div class="d-flex mt-2">
                                <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="danger" class="mr-2" type="submit">
                                    {{ liveId ? "update" : "add" }}
                                </b-button>
                                <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" type="reset" variant="outline-danger"> reset </b-button>
                            </div>
                        </b-col>
                    </b-row>
                </b-form>
            </validation-observer>
        </template>
    </component>
</template>

<script>
import { BTab, BTabs, BCard, BAlert, BFormGroup, BForm, BRow, BCol, BFormInput, BMedia, BFormFile, BImg, BFormTextarea, BCardText, BButton, BMediaBody, BMediaAside, BFormInvalidFeedback, BFormDatepicker, BFormTimepicker, BFormCheckbox } from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import formValidation from "@core/comp-functions/forms/form-validation";
import { required } from "@validations";
import { ref } from "@vue/composition-api";
import router from "@/router";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import { makeid } from "@/utils/math";
import useLiveChatList from "./useLiveChatList";
import useGenre from "../genre/useGenre";
import useFile from "../file/useFile";
import useFile2 from "./useFile";
const RANDOM_TOKEN = makeid(50);
const RANDOM_TOKEN2 = makeid(50);
export default {
    components: {
        BTab,
        BFormDatepicker,
        BFormCheckbox,
        BFormTimepicker,
        BFormInput,
        BFormInvalidFeedback,
        BFormGroup,
        BForm,
        BFormTextarea,
        BFormFile,
        BImg,
        BMedia,
        BTabs,
        BCard,
        BAlert,
        BRow,
        BCol,
        vSelect,
        BButton,
        BCardText,
        BMediaAside,
        BMediaBody,
        // Form Validation
        ValidationProvider,
        ValidationObserver,
    },
    directives: {
        Ripple,
    },
    setup() {
        let blankForm = {
            title: "",
            // start_time: "",
            show_home_banner: false,
            show_post_banner: false,
            file_batch_id_home: RANDOM_TOKEN,
            file_batch_id_post: RANDOM_TOKEN2,
        };
        const start_time = ref();
        const time = ref();
        var today = new Date();
        var dd = String(today.getDate()).padStart(2, "0");
        var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
        var yyyy = today.getFullYear();
        const date = ref(yyyy + "-" + mm + "-" + dd);
        const liveId = ref(null);

        const form = ref(JSON.parse(JSON.stringify(blankForm)));

        if (router.currentRoute.params.id) {
            liveId.value = router.currentRoute.params.id;
        }

        const { storeFile, fileForm } = useFile(RANDOM_TOKEN, "main_image", "live_chats");
        const { postStoreFile, postFileForm } = useFile2(RANDOM_TOKEN2, "post_image", "live_chats");
        const { storeLiveChat, updateLiveChat, showLiveChat } = useLiveChatList();
        const { fetchGenre, genres } = useGenre();
        // Register module

        fetchGenre();

        const onSubmit = async () => {
            console.log(form.value);
            if (form.value.thumbnail) {
                delete form.value.thumbnail;
            }
            if (form.value.thumbnail2) {
                delete form.value.thumbnail2;
            }
            if (fileForm.value.file) {
                await storeFile();
            }
            if (postFileForm.value.file) {
                await postStoreFile();
            }
            if (liveId.value) {
                form.value.start_time = `${start_time.value} ${time.value}`;

                updateLiveChat(liveId.value, form.value).then((res) => {
                    router.push({ name: "apps-live-chat-list" });
                });
            } else {
                form.value.start_time = `${start_time.value} ${time.value}`;
                storeLiveChat(form.value).then((res) => {
                    router.push({ name: "apps-live-chat-list" });
                });
            }
        };

        // Reset Validation
        const resetData = () => {
            form.value = JSON.parse(JSON.stringify(blankForm));
        };

        const { refFormObserver, getValidationState, resetForm } = formValidation(resetData);
        const videoSrc = ref();
        // fill Form When edit page
        const showData = async () => {
            const response = await showLiveChat(liveId.value);
            if (response) {
                form.value = { ...blankForm, ...response };
                blankForm = { ...blankForm, ...response };
            } else {
                form.value = undefined;
            }
            start_time.value = response.start_time.split(" ")[0];
            time.value = response.start_time.split(" ")[1];
            videoSrc.value = response.video;
            form.value.show
            console.log(response);
        };

        if (liveId.value) {
            showData();
        }

        return {
            form,
            fileForm,
            onSubmit,
            postFileForm,
            refFormObserver,
            getValidationState,
            resetForm,
            required,
            liveId,
            genres,
            date,
            time,
            videoSrc,
            start_time,
        };
    },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
