import { ref } from '@vue/composition-api'

// Notification
import GenreRepository from '@/abstraction/repository/genreRepository'

const repository = new GenreRepository()
export default function useGenre() {
    const genres = ref([])
    const pagination = ref({})

    const fetchGenre = async (params= { pagination: { page: 1 } }) => {
        try {
            const response = await repository.index(params)
            pagination.value = response.pagination
            genres.value = response.data
            return response
        } catch (e) {
            return false
        }
    }

    // *===============================================---*
    // *--------- UI ---------------------------------------*
    // *===============================================---*

    return {
        genres,
        fetchGenre,
    }
}
