import { SetPagination, SetQueriesObject } from "@/utils/setQueriesObject";

export const getJson = (data) => ({
    id: data.id,
    title: data.title,
    creator: data.creator,
    link: data.link,
    text: data.text,
    activeStatus: data.is_live_active,
    status: data.status,
    start_time: data.start_time,
    thumbnail: data.thumbnail,
    thumbnail2: data.post_thumbnail,
    show_post_banner: data.show_post_banner,
    show_home_banner: data.show_home_banner,
    video: data.video ? data.video.progressive[0].link : null,
});
export const getArray = ({ data, meta }) => {
    const pagination = SetPagination(meta);
    data = data.map((playList) => getJson(playList));
    return { data, pagination };
};
export const setData = (data) => ({
    title: data.title,
    link: data.link,
    text: data.text,
    start_time: data.start_time,
    file_batch_id_main: data.file_batch_id_main,
    file_batch_id_home: data.file_batch_id_home,
});
export const setQuery = (data) => SetQueriesObject(data);
