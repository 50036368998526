import axios from "axios";
import url from "../url";
import { getArray, setQuery, getJson, setData } from "../resources/liveChatResource";

export default class PlayListRepository {
    constructor(config = {}) {
        axios.defaults.errorHandle = config.errorHandle;
    }

    async index(data) {
        const params = setQuery(data);
        const response = await axios.get(url("indexLiveChat"), { params });
        if (response.status === 200) {
            return getArray(response.data);
        }
    }
    async liveLastMessagesHandler(data, id) {
        const params = setQuery(data);
        const response = await axios.get(url("liveLastMessages", { liveId: id }), { params });
        if (response.status === 200) {
            return response;
        }
    }
    async showActiveLive() {
        const response = await axios.get(url("showActiveLive"));
        if (response.status === 200) {
            return response;
        }
    }
    async show(liveID) {
        const response = await axios.get(url("showLiveChat", { live: liveID }));
        if (response.status === 200) {
            return getJson(response.data.data);
        }
    }
    async banUsers(liveID) {
        const response = await axios.get(url("banUserList", { live: liveID }));
        if (response.status === 200) {
            return response.data.data;
        }
    }
    async ban(userId, liveId) {
        const response = await axios.get(url("banUser", { live: liveId, user: userId }));
        if (response.status === 200) {
            return getJson(response.data.data);
        }
    }
    async unban(userId, liveId) {
        const response = await axios.get(url("unbanUser", { live: liveId, user: userId }));
        if (response.status === 200) {
            return getJson(response.data.data);
        }
    }
    async addLiveVideo(liveId, vimeoId) {
        const response = await axios.get(url("addLiveVideo", { live: liveId, vimeo: vimeoId }));
        if (response.status === 200) {
            return response;
        }
    }
    async activeChat(liveId) {
        const response = await axios.get(url("activeChat", { live: liveId }));
        if (response.status === 200) {
            return response;
        }
    }
    async deActiveChat(liveId) {
        const response = await axios.get(url("deActiveChat", { live: liveId }));
        if (response.status === 200) {
            return response;
        }
    }
    async pinMsg(msgId) {
        const response = await axios.get(url("pinMessage", { id: msgId }));
        if (response.status === 200) {
            return response;
        }
    }
    async unPinMsg(msgId) {
        const response = await axios.get(url("unPinMessage", { id: msgId }));
        if (response.status === 200) {
            return response;
        }
    }
    async pinedMsg(liveId) {
        const response = await axios.get(url("pinedMessage", { live: liveId }));
        if (response.status === 200) {
            return response;
        }
    }
    async activeLive(liveId) {
        const response = await axios.get(url("activeLive", { live: liveId }));
        if (response.status === 200) {
            return response;
        }
    }
    async deActiveLive(liveId) {
        const response = await axios.get(url("deActiveLive", { live: liveId }));
        if (response.status === 200) {
            return response;
        }
    }
    async finishChat(liveId) {
        const response = await axios.get(url("finishChat", { live: liveId }));
        if (response.status === 200) {
            return response;
        }
    }
    async store(data) {
        console.log(data);
        const response = await axios.post(url("storeLiveChat"), data);
        if (response.status === 201) {
            return response.data;
        }
    }
    async addNewMessage(data) {
        console.log(data);
        const response = await axios.post(url("addNewMessage"), data);
        if (response.status === 201) {
            return response.data;
        }
    }
    async uploadVideo(data) {
        console.log(data);
        const response = await axios.post(url("uploadVideo"), data);
        if (response.status === 201) {
            return response.data;
        }
    }
    async update(id, data) {
        console.log(data);
        const json = setData(data);
        const response = await axios.put(url("updateLiveChat", { live: id }), data);
        if (response.status === 200) {
            return response.data.data;
        }
    }
    async liveLikes(liveId, data) {
        const response = await axios.put(url("liveChatLikes", { id: liveId }), data);
        if (response.status === 200) {
            return response.data.data;
        }
    }
    async updateLiveBanner(liveId, data) {
        const response = await axios.put(url("liveChatBanner", { id: liveId }), data);
        if (response.status === 200) {
            return response.data.data;
        }
    }
    async liveBannerStatus(liveId) {
        const response = await axios.get(url("liveChatBannerStatus", { id: liveId }));
        if (response.status === 200) {
            return response.data.data;
        }
    }
    async resetLiveLikes(liveId) {
        const response = await axios.get(url("resetLiveLikes", { id: liveId }));
        if (response.status === 200) {
            return response.data.data;
        }
    }
    async hideLiveBanner(liveId) {
        const response = await axios.get(url("hideLiveBanner", { id: liveId }));
        if (response.status === 200) {
            return response;
        }
    }
    async showLiveBanner(liveId) {
        const response = await axios.get(url("showLiveBanner", { id: liveId }));
        if (response.status === 200) {
            return response;
        }
    }
    async updateSort(playlistId) {
        const response = await axios.put(url("updateSortMoods", { mood: playlistId }));
        if (response.status === 200) {
            return response.data.data;
        }
    }
    async deleteMsg(id) {
        const response = await axios.delete(url("deleteMsg", { live: id }));
        if (response.status === 200) {
            return true;
        }
    }
    async delete(id) {
        const response = await axios.delete(url("deleteLiveChat", { live: id }));
        if (response.status === 200) {
            return true;
        }
    }

    async attach(moodId, musicId) {
        const json = { music_id: musicId };
        const response = await axios.post(url("attachMoods", { mood: moodId }), json);
        if (response.status === 200) {
            return response.data.data;
        }
    }

    async detach(moodId, musicId) {
        const json = { music_id: musicId };
        const response = await axios.post(url("detachMoods", { mood: moodId }), json);
        if (response.status === 200) {
            return response.data.data;
        }
    }
    async storeLiveStreamData(data) {
        const response = await axios.post(url("storeLiveStreamData"), data);
        if (response.status === 201) {
            return response.data;
        }
    }
    async showLiveStreamData(liveID) {
        const response = await axios.get(url("showLiveStreamData", { live: liveID }));
        if (response.status === 200) {
            return response.data.data;
        }
    }

    async showLiveStreamRequests(liveID) {
        const response = await axios.get(url("showLiveStreamRequests", { live: liveID }));
        if (response.status === 200) {
            return response.data.data;
        }
    }
    async inviteUserToStream(data) {
        const response = await axios.post(url("inviteUserToStream"), data);
        if (response.status === 201) {
            return response.data;
        }
    }
    async cancelInvite(data) {
        const response = await axios.post(url("cancelInvite"), data);
        if (response.status === 201) {
            return response.data;
        }
    }
    async endStream(liveId, userId) {
        const response = await axios.get(url("endStream", { live: liveId, user: userId }));
        if (response.status === 200) {
            return response.data.data;
        }
    }
    async removeRequest(id) {
        const response = await axios.delete(url("removeRequest", { id: id }));
        if (response.status === 200) {
            return true;
        }
    }

}
